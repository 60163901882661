import Vue from 'vue';
import VueMeta from 'vue-meta';
import Router from 'vue-router';

Vue.use(Router);
Vue.use(VueMeta);

function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

export default new Router({
    mode: 'history',
    hashbang: false,
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    linkExactActiveClass: 'current',

    scrollBehavior: async (to, from, savedPosition) => {
        const findEl = async (hashAnchor, x) => {
            const el = document.getElementById(hashAnchor);
            if (el !== null) {
                return el;
            }
            return new Promise((resolve) => {
                if (x > 10) {
                    return resolve();
                }
                setTimeout(() => { resolve(findEl(hashAnchor, ++x || 1)); }, 200);
            });
        };

        const hashAnchor = to.meta.anchor;
        if (hashAnchor) {
            let el = await findEl(hashAnchor);
            if (el === undefined) {
                return { x: 0, y: 0 };
            }
            let yOffset = 150;
            if (el.classList.contains('visible')) {
                yOffset = 0;
            }
            const topPosition = el.getBoundingClientRect().top + window.scrollY - yOffset;
            if ('scrollBehavior' in document.documentElement.style) {
                return window.scrollTo({ top: topPosition, behavior: 'smooth' });
            }
            return window.scrollTo(0, topPosition);
        }
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/about-us',
            hash: '#AboutUs',
            name: 'AboutUs',
            component: loadView('Discover'),
            meta: {
                anchor: 'AboutUsPage',
                title: 'About us',
                label: 'About us',
                shortLabel: 'About us',
                discover: true,
                description: 'We’re a B2B growth agency, founded by Nick Fox. Software-centric, augmented by strategic and creative services delivered into a niche where we are experts.'
            }
        },
        {
            path: '/salesmakers',
            hash: '#SalesMakers',
            name: 'SalesMakers',
            component: loadView('Discover'),
            meta: {
                anchor: 'SalesMakersPage',
                title: 'Meet the team',
                label: 'Meet the team',
                shortLabel: 'Meet the team',
                discover: true,
                description: 'We’re a team of creatives, disruptors, troublemakers and dreamers, spending every day trying to save B2B buyers from bad sales practices.'
            }
        },
        {
            path: '/the-fox-den',
            hash: '#TheFoxDen',
            name: 'TheFoxDen',
            component: loadView('Discover'),
            meta: {
                anchor: 'TheFoxDenPage',
                title: 'The fox den',
                label: 'The fox den',
                shortLabel: 'The fox den',
                discover: true,
                description: 'We’re a fully digital team, effortlessly connecting across three different continents and four time zones.'
            }
        },
        {
            path: '/clients',
            hash: '#Clients',
            name: 'Clients',
            component: loadView('Expertise'),
            meta: {
                anchor: 'ClientsPage',
                title: 'Clients',
                label: 'Clients',
                shortLabel: 'Clients',
                discover: true,
                keywords: ['New Zealand Post', 'Fronde', 'VXI'],
                description: 'Learn about our approach to Sale Enablement, Go-to-Market Transformation and Digital Account Management.'
            }
        },
        {
            path: '/partners',
            hash: '#Partners',
            name: 'Partners',
            component: loadView('Expertise'),
            meta: {
                anchor: 'PartnersPage',
                title: 'Partners',
                label: 'Partners',
                shortLabel: 'Partners',
                discover: true,
                keywords: ['RELM', 'Ackama', 'Monsoon Creative'],
                description: 'Here are our software, development and creative partners.'
            }
        },
        {
            path: '/digital-selling',
            hash: '#DigitalSelling',
            name: 'DigitalSelling',
            component: loadView('Expertise'),
            meta: {
                anchor: 'DigitalSellingPage',
                title: 'B2B digital selling',
                label: 'B2B digital selling',
                shortLabel: 'B2B digital sales',
                expertise: true,
                description: 'Modern and effective Business Development is about reaching the right people, in the right way, at the right time.'
            }
        },
        {
            path: '/go-to-market',
            hash: '#GoToMarket',
            name: 'GoToMarket',
            component: loadView('Expertise'),
            meta: {
                anchor: 'GoToMarketPage',
                title: 'Go-to-market transformation',
                label: 'Go-to-market transformation',
                shortLabel: 'G2M transformation',
                expertise: true,
                description: 'We bring together our team of experts to focus on the individual context of your business and reinvent your sales and marketing model.'
            }
        },
        {
            path: '/sales',
            hash: '#Sales',
            name: 'Sales',
            component: loadView('Expertise'),
            meta: {
                anchor: 'SalesPage',
                title: 'Sales effectiveness',
                label: 'Sales effectiveness',
                shortLabel: 'Sales effectiveness',
                expertise: true,
                description: 'We enable sales to be effective - using custom technology and techniques that reposition your business to build relevance with your target audience, at scale.'
            }
        },
        {
            path: '/strategic',
            hash: '#Strategic',
            name: 'Strategic',
            component: loadView('Expertise'),
            meta: {
                anchor: 'StrategicPage',
                title: 'Strategic marketing',
                label: 'Strategic marketing',
                shortLabel: 'Strategic marketing',
                expertise: true,
                description: 'We combine design thinking, agile methodology and lean experimentation to help you deliver more value from your marketing spend.'
            }
        },
        {
            path: '/content-creation',
            hash: '#ContentCreation',
            name: 'ContentCreation',
            component: loadView('Expertise'),
            meta: {
                anchor: 'ContentCreationPage',
                title: 'Content creation',
                label: 'Content creation',
                shortLabel: 'Content creation',
                expertise: true,
                description: 'By creating the right content, we help you connect with those you want to reach, on their terms and in their language. Building trust and making magic happen.'
            }
        },
        {
            path: '/welcome-to-jfi',
            hash: '#ExpertiseIntro',
            name: 'WelcomeToJFI',
            component: loadView('Expertise'),
            meta: {
                anchor: 'ExpertiseIntroPage',
                title: 'Welcome to JFI',
                description: 'We’re a niche B2B sales and marketing consultancy and software company.'
            }
        },
        {
            path: '/client-stories/:client',
            hash: '#ClientStoriesProject',
            name: 'ClientStories',
            component: loadView('ClientStories'),
            meta: {
                anchor: '',
                title: 'Client Stories'
            }
        },
        {
            path: '/',
            hash: '#WelcomeAnimation',
            name: 'Home',
            component: loadView('Expertise'),
            meta: {
                anchor: 'WelcomeAnimationPage',
                title: 'Home',
                label: 'Home',
                shortLabel: 'Home'
            }
        },
        {
            path: '/discover',
            redirect: { name: 'AboutUs' },
            meta: {}
            //hash: '#AboutUs',
            //name: 'Discover',
            //component: loadView('Discover'),
            //meta: {
            //  anchor: 'AboutUsPage',
            //  title: 'Discover'
            //}
        },
        {
            path: '/expertise',
            redirect: { name: 'Home' },
            meta: {}
            //hash: '#WelcomeAnimation',
            //name: 'Expertise',
            //component: loadView('Expertise'),
            //meta: {
            //  anchor: 'WelcomeAnimationPage',
            //  title: 'Expertise'
            //}
        },
        {
            path: '*',
            redirect: { name: 'Home' },
            meta: {}
        }
    ]
});
