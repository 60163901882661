<template>
    <div :class="[navigationIsOpened ? 'open':'']" class="Wrapper" id="app">
        <SVGSprites />
        <Header />
        <div class="Wrapper-main" id="Page">
            <router-view />
        </div>
        <Footer />
    </div>
</template>

<script>
    import router from '@/router';
    import store from '@/store';
    import SVGSprites from '@/assets/SVGSprites.svg';
    import 'vue-loading-overlay/dist/vue-loading.css';

    router.beforeEach((to, from, next) => {
        store.dispatch('burgerMenuClose');
        next();
    });

    export default {
        name: 'App',
        metaInfo() {
            const defaultMeta = store.state.meta;
            const pageMeta = this.$route.meta;
            const title = pageMeta.title;
            //const keywords = pageMeta.keywords !== undefined ? pageMeta.keywords : defaultMeta.keywords;
            const description = pageMeta.description !== undefined ? pageMeta.description : defaultMeta.description;
            const canonicalUrl = `${this.$route.path}`;
            if (this.$route.name !== null) {
                return {
                    title: title,
                    titleTemplate: '%s - Jumping Fox Interactive',
                    meta: [
                        // Keywords
                        //{ vmid: 'keywords', name: 'keywords', content: keywords },
                        // Standard
                        { vmid: 'subject', name: 'subject', content: description },
                        { vmid: 'description', name: 'description', content: description },
                        // OpenGraph Data
                        { vmid: 'og:title', name: 'og:title', content: title },
                        { vmid: 'og:description', name: 'og:description', content: description },
                        { vmid: 'og:url', name: 'og:url', content: canonicalUrl },
                        // Twitter card
                        { name: 'twitter:card', content: 'summary' },
                        { vmid: 'twitter:title', name: 'twitter:title', content: title },
                        { vmid: 'twitter:description', name: 'twitter:description', content: description },
                        { vmid: 'twitter:site', name: 'twitter:site', content: canonicalUrl },
                        // Google / Schema.org markup:
                        { vmid: 'g:name', itemprop: 'name', content: title },
                        { vmid: 'g:description', itemprop: 'description', content: description }
                    ],
                    link: [
                        { vmid: 'canonical', rel: 'canonical', href: canonicalUrl }
                    ]
                };
            }
        },
        data() {
            return {
                sceneTimer: null,
                loaderTimer: null,
                loader: null
            };
        },
        components: {
            SVGSprites,
            'Header': () => import('@/components/Header'),
            'Footer': () => import('@/components/Footer')
        },
        watch: {
            bgClass: function (newValue) {
                document.body.removeAttribute('class');
                if (newValue !== '') {
                    document.body.classList.add(newValue);
                }
            },
            '$store.state.anchorToReach': function () {
                this.onMountedUrl();
            }
        },
        created() {
            this.loader = this.$loading.show();
        },
        mounted() {
            this.$root.$on('activeLink', this.closeNavigation);
            this.$root.$on('toggleExpertise', this.toggleExpertise);
            this.$root.$on('toggleNavigation', this.toggleNavigation);
            this.$root.$on('closeNavigation', this.closeNavigation);

            this.loader.hide();
            this.$nextTick(() => {
                this.loaderTimer = setInterval(this.createScene, 1000);
            });
        },
        computed: {
            navigationIsOpened: () => {
                return store.state.navigationIsOpened;
            },
            bgClass: () => {
                return store.state.bgClass;
            }
        },
        methods: {
            createScene() {
                clearInterval(this.loaderTimer);
                this.onMountedUrl();
                const event = new CustomEvent('render-done');
                document.dispatchEvent(event); // this event is catch by PrerenderSPAPlugin
            },
            onMountedUrl() {
                if (this.$store.state.anchorToReach !== null) {
                    return this.scrollToAnchor(this.$store.state.anchorToReach);
                }
                if (this.$router.currentRoute !== null) {
                    const hashAnchor = this.$router.currentRoute.meta.anchor;
                    if (hashAnchor !== undefined || hashAnchor !== '') {
                        store.dispatch('setAnchorToReach', hashAnchor);
                        return this.scrollToAnchor(hashAnchor);
                    }
                }
            },
            scrollToAnchor: async function (hashAnchor) {
                const findEl = async (hashAnchor, x) => {
                    const el = document.getElementById(hashAnchor);
                    if (el !== null) {
                        return el;
                    }
                    return new Promise((resolve) => {
                        if (x > 10) {
                            return resolve();
                        }
                        setTimeout(() => { resolve(findEl(hashAnchor, ++x || 1)); }, 200);
                    });
                };
                let el = await findEl(hashAnchor);
                if (el === undefined) {
                    return { x: 0, y: 0 };
                }
                let yOffset = 150;
                if (el.classList.contains('visible')) {
                    yOffset = 0;
                }
                const topPosition = el.getBoundingClientRect().top + window.scrollY - yOffset;
                if ('scrollBehavior' in document.documentElement.style) {
                    return window.scrollTo({ top: topPosition, behavior: 'smooth' });
                }
                return window.scrollTo(0, topPosition);
            },
            toggleExpertise: function () {
                this.$store.dispatch('expertiseBurgerMenuToggle');
            },
            toggleNavigation: function () {
                this.$store.dispatch('burgerMenuToggle');
            },
            closeNavigation: function () {
                this.$store.dispatch('burgerMenuClose');
            }
        }
    };
</script>

<style lang="scss" rel="preload">
    @import "scss/App";
</style>
