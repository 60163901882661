import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        meta: {
            title: 'Jumping Fox Interactive',
            keywords: ['Jumping Fox Interactive', 'Jumping Fox', 'JFI', 'SalesMaking'],
            description: 'SalesMaking. The approach your customers would design for you. We’re a niche B2B sales and marketing consultancy and software company.'
        },
        socials: [
            {
                name: 'LinkedIn',
                url: 'https://nz.linkedin.com/company/jumping-fox-interactive-ltd'
            },
            {
                name: 'Twitter',
                url: 'https://twitter.com/Jumpingfoxteam'
            }
        ],
        offices: [
            {
                headquarter: true,
                country: 'New Zealand',
                city: 'Wellington',
                email: 'hey@jumpingfox.nz',
                address: `Berl House<br/>Level 3<br/>108 The Terrace<br/>Wellington 6011`,
                gmap: 'https://www.google.com/maps/@-41.2827296,174.7748184,18z'
            },
            {
                country: 'New Zealand',
                city: 'Auckland',
                address: `Level 3<br/>33-45 Hurstmere Road<br/>Takapuna<br/>Auckland`
            },
            {
                country: 'Australia',
                city: 'Melbourne',
                address: `Depo8<br/>39-41 Mount Street<br/>Prahran VIC 3181<br/>Melbourne`
            },
            {
                country: 'Canada',
                city: 'Toronto',
                email: 'hey@jumpingfoxca.com',
                address: `Bloor Street<br/>Toronto ON`
            },
            {
                country: 'Belgium',
                city: 'Brussels',
                address: `Rue Franz Merjay<br/>Brussels`
            }
        ],
        teamMembers: [
            {
                firstName: 'Nick',
                lastName: 'Fox',
                color: '#f1ebe0',
                job: 'Founder and CEO',
                wantedFor: 'Strategy & execution'
                //foxPicture: 'https://placeimg.com/200/200/any?15'
            },
            {
                firstName: 'Rob',
                lastName: 'Wilson',
                color: '#fde8f1',
                job: 'Co-Founder North America',
                wantedFor: 'Creative plots'
            },
            {
                firstName: 'Hamish',
                lastName: 'Mitchell',
                color: '#c2ffc2',
                job: 'Managing Partner',
                wantedFor: 'Trading secrets'
            },
            {
                firstName: 'Chloe',
                lastName: 'Narain',
                color: '#e5dbec',
                job: 'Customer Engagement Lead & Copywriter',
                wantedFor: 'Weaponising content'
            },
            {
                firstName: 'Cilla',
                lastName: 'Nicholls',
                color: '#fee9d8',
                job: 'Customer Engagement Lead',
                wantedFor: 'Campaign detonation'
            },
            {
                firstName: 'Harry',
                lastName: 'Winton',
                color: '#fffac2',
                job: 'Strategic Visual Lead',
                wantedFor: 'Design illusions'
            },
            {
                firstName: 'Kaliana',
                lastName: 'French',
                //color: '#fffce0',
                color: '#b1ded2',
                job: 'Creative Marketing Strategist',
                wantedFor: 'Time travelling'
            },
            {
                firstName: 'Ollie',
                lastName: 'Fox',
                //color: '#b1ded2',
                color: '#d1eefc',
                job: 'Research and Media',
                wantedFor: 'Cyber stalking'
            },
            {
                firstName: 'Sarah',
                lastName: 'Fox',
                //color: '#d1eefc',
                color: '#fde8f1',
                job: 'Office Manager & Director',
                wantedFor: 'Leaving breadcrumbs'
            },
            {
                firstName: 'Sonia',
                lastName: 'Barcham',
                color: '#f6f1d5',
                job: 'Customer Engagement Lead',
                wantedFor: 'Customer entrapment'
            },
            {
                firstName: 'Steve',
                lastName: 'Keegan',
                color: '#e1f4fd',
                job: 'Sales Enablement Strategist',
                wantedFor: 'Thought disruption'
            },
            {
                firstName: 'Tori',
                lastName: 'Crawford-Flett',
                //color: '#daeffc',
                color: '#b1ded2',
                job: 'Customer Engagement Lead',
                wantedFor: 'Digital booby trapping',
                realPicture: '/img/foxes/ToriCrawford-Flett_real2.png'
            },
            {
                firstName: 'William',
                lastName: 'Quéant',
                color: '#cccccc',
                //color: '#fffbcf',
                job: 'Lead Developer',
                wantedFor: 'Technical stuff we don’t understand'
            }
        ],
        expertises: [
            {
                key: 'DigitalSelling',
                label: 'B2B digital selling',
                shortLabel: 'B2B digital sales',
                wrappedLabel: 'B2B digital<br/>selling',
                headerWord: 'The sales role broke years ago. We’ve finally fixed it.',
                illustrations: [
                    'foxRob.png',
                    'foxKaliana.png'
                ],
                pattern: 'Plant',
                text: `<p>When nearly all of today's B2B sales teams ‘have gone digital’ but only 43% are hitting targets, something’s seriously wrong.</p>
<p>‘Digital’ isn't just about making existing processes more efficient and reaching more people.</p>
<p>It’s about reaching the <b><i>right</i></b> people, in the <b><i>right</i></b> way, at the <b><i>right</i></b> time. That’s where we come in.</p>
<p>We help you rebuild your sales engine from the buyer back, aligning your approach to their journey, and giving you a unique advantage to close more quality deals - faster.</p>`
            },
            {
                key: 'GoToMarket',
                label: 'Go-to-market transformation',
                shortLabel: 'G2M transformation',
                wrappedLabel: 'Go-to-Market<br/>transformation',
                headerWord: 'Reinvention starts with you.',
                illustrations: ['foxOnBike.png'],
                bg: 'Shapes',
                text: `<p>When the muscle memory of your organisation is simply reinforcing the status quo, keeping up with your customers will always be an uphill battle. You know the sales results you seek require change, but it’s hard. And the biggest change needed - behaviour - is hardest of all.</p>
<p>We’ve led our share of change initiatives where time is short and expectations are high. We help you break things down into bite-sized chunks so you can eat the elephant one mouthful at a time.</p>
<p>Jumping into the trenches alongside you, we bring our global team to focus on the individual context of your business and reinvent your sales and marketing model.</p>`
            },
            {
                key: 'Sales',
                label: 'Sales effectiveness',
                wrappedLabel: 'Sales<br/>effectiveness',
                headerWord: 'Selling doesn’t lead to sales. Guidance, trust and empathy do.',
                illustrations: ['fishingFox.png'],
                pattern: 'Shapes2',
                text: `<p>On average there are 6.8 decision-makers in any deal. More buyers, more viewpoints, more effort. It’s no wonder the average deal time has increased by 25% over the last 6 years.</p>
<p>Typical sales enablement doesn’t focus on building credibility with time-poor buyers. It's more focussed on pitching instead of guiding. Efficiency instead of effectiveness.</p>
<p>We help your sales team <b>enable sales to be effective</b> - using custom technology and techniques that reposition your front line to build relevance with their target audience, at scale.</p>`
            },
            {
                key: 'Strategic',
                label: 'Strategic marketing',
                wrappedLabel: 'Strategic<br/>marketing',
                headerWord: 'Creating magic at the intersection of design, data and your customers.',
                illustrations: ['waitingFox.png'],
                pattern: 'Shapes2',
                text: `<p>Marketers are constantly being asked to achieve more, with less. How do you make sure you are employing the right tactics, being informed by the right data, and are flexible enough to evolve at the true speed of the customer?</p>
<p>As a team of modern marketers, we are adaptive experimenters, testing, trialing and exploring with our customers, delivering better results in shorter time frames.</p>
<p>Whether it’s thousands of customers or an audience of one, we combine design thinking, agile methodology and lean experimentation to help you deliver more value from your marketing spend.</p>`
            },
            {
                key: 'ContentCreation',
                label: 'Content creation',
                wrappedLabel: 'Content<br/>creation',
                headerWord: 'Be the signal, not the noise. Are you shouting to be heard?',
                illustrations: [
                    'foxChloe.png',
                    'foxHamish.png'
                ],
                pattern: 'Plant',
                text: `<p>Just last year, the amount of B2B content published increased by 300%. How much has customer engagement increased with this content? Zero.</p>
<p>The traditional approach to content is backward. Too many sellers talk about their business, their product or deliver ‘insight’ that isn’t understood or valued - and it’s definitely not resulting in more deals. Go check your spam folder if you don’t believe us.</p>
<p>Our team bridges the gap by becoming the content engine you need to get results today - while training your team to create the right content themselves for tomorrow.</p>
<p>Whether its video, animation, articles or infographics, we’ll help you connect with those you want to reach, on their terms and in their language. Building trust and making magic happen.</p>`
            }
        ],
        ourClients: [
            {
                key: 'DatamaxSolutions',
                name: 'Datamax Solutions'
            },
            {
                key: 'ExcelDigital',
                name: 'Excel Digital'
            },
            {
                key: 'Fronde',
                name: 'Fronde',
                story: {
                    order: 2,
                    heading: 'Go-to-Market Transformation',
                    icon: 'symbol-GoToMarket',
                    services: [
                        'Go-to-market transformation',
                        'Content strategy & creation',
                        'Sales coaching',
                        'Sales & marketing alignment',
                        'Digital outreach deployment & management'
                    ],
                    pictures: [
                        'CoverFronde.jpg',
                        'stock-photo-man-pointing-at-laptop-for-his-colleague-233268232.jpg'
                    ],
                    challenge: `
<p><b>We struggled with pipeline credibility</b>. How many of our leads were actually going to turn into a deal? We knew we needed to broaden our audience, whether that be with new or existing customers. We’d tried to do that in a fairly traditional way - events, meetings, emails and phone calls, but we weren’t getting the traction we needed. We were looking for an alternative way to educate the audience on what it is that Fronde actually does - without expending a bunch on expensive sales resource.</p>`,
                    rose: `<div class="column"><p>JFI really helped lift us out of own heads when it came to the story we needed to tell customers. Traditionally, people spend about 80% of their time talking about the company they’re working for and just 20% about the customer and their needs.</p></div>
<div class="column"><p>We needed to flip that around. JFI enabled us to make that change, while also levelling up our storytelling ability to engage the audience that really mattered: the executive team.</p></div>`,
                    thorn: `<div class="column"><p>We were forced to face the reality that our customer database really wasn’t up to scratch, nor were we collecting data on our desired target audience. JFI spent a lot of time with us on this, which we hadn’t originally planned.</p></div>
<div class="column"><p>But they cleaned up the database and built a process we could use going forward, while helping us find our REAL target audience.</p></div>`,
                    banana: `<div class="column"><p>We’ve ended up using the JFI methodology within our recruiting process to ensure the sellers we were onboarding were aligned our new digital first approach - and we also positioned our roles as an opportunity to genuinely do things differently.</p></div>
<div class="column"><p>JFI worked closely with the sellers to move from what was traditional selling to a digital first world - and that wasn’t an easy task. But having JFI working with us our sales team had the support they needed to make this change.</p></div>`,
                    results: `<p>A transformed go-to-market approach.</p>`,
                    stats: `20% engagement from CEOs in our direct marketing campaigns.`,
                    author: {
                        name: 'Mike Roxburgh',
                        role: 'GM of Sales & Marketing'
                    }
                }
            },
            {
                key: 'Hunchbuzz',
                name: 'Hunchbuzz'
            },
            {
                key: 'IntegrationQA',
                name: 'Integration QA'
            },
            {
                key: 'iText',
                name: 'iText'
            },
            {
                key: 'NewZealandPost',
                name: 'New Zealand Post',
                story: {
                    order: 3,
                    comingSoon: true,
                    heading: 'Digital Account Management',
                    services: [],
                    icon: 'symbol-DigitalSelling',
                    pictures: [
                        'CoverNewZealandPost.jpg'
                    ],
                    challenge: ``,
                    rose: ``,
                    thorn: ``,
                    banana: ``,
                    results: ``,
                    stats: ``,
                    author: {
                        name: '',
                        role: ''
                    }
                }
            },
            {
                key: 'PrimaryITO',
                name: 'Primary ITO'
            },
            {
                key: 'SinglePointAssist',
                name: 'SinglePoint Assist'
            },
            {
                key: 'Spark',
                name: 'Spark'
            },
            {
                key: 'VXI',
                name: 'VXI',
                story: {
                    order: 1,
                    heading: 'Sales Enablement',
                    services: [
                        'Go-to-market strategy and execution',
                        'Sales coaching and transformation',
                        'Direct email marketing design and management',
                        'Story design and content creation'
                    ],
                    pictures: [
                        'CoverVXI.jpg',
                        'stock-photo-view-of-staff-in-busy-customer-service-department-547750414.jpg'
                    ],
                    icon: 'symbol-Sales',
                    challenge: `<p>We were a small team tasked with achieving some aggressive growth targets. Relatively unknown in the market, at VXI not only did we need to create <b>a compelling go-to-market, but to rebuild our sales and marketing processes to deliver better deal quality and pipeline velocity</b>. In a crowded market, differentiation was of utmost importance - aligning ourselves to the problems our customers needed solving, no matter where they were in their business or their own personal buying journey.</p>`,
                    rose: `<div class="column"><p>JFI supported us by giving the tools and the skill sets to have the right conversations with the right targets - at scale.</p>
<p>Our biggest success with JFI was having them embedded in the team, providing that ‘outside view’ into what really makes our business tick - what drives potential customers to want to speak to us, and the problems that we actually solve.</p></div>
<div class="column"><p>They did this by:</p>
<ul>
    <li>articulating our core value proposition and what that means for our customers.</li>
    <li>getting under the hood with our sellers, changing the traditional way of selling.</li>
    <li>coaching our sellers to drive thought leadership in the marketplace, and to take that story from 1-1, to 1-many. This allowed our sellers to reposition in the digital way of selling.</li>
</ul></div>
<div class="column"><p><b>By doing this we had a compelling story that resonated with our customer base, solving their problems and driving superior results.</b></p></div>`,
                    thorn: `<div class="column"><p>The main pain problem we faced was our own. Change will always be a challenge within a very traditional sales team. We had to buy into JFI’s way of the transformational journey and their approach to selling in the modern age. It takes a lot to drive change - to have sales and marketing to step outside of their comfort zone.</p></div>
<div class="column"><p>What I can say is JFI adapted to each seller’s attributes and behavioural traits - of what makes them tick and what their motivational drivers are. That said - to this day, not everybody is onboard with the JFI approach.</p></div>`,
                    banana: `<div class="column"><p>They opened our eyes as to what it takes to sell and win in the modern age. It was encouraging how immediate the impact was - we had some quick wins in Q1, and Q2 was about building out the foundations we needed for sales success. A big part of that was around marketing and sales funnel alignment, doing both together seemed like a monumental task.</p></div>
<div class="column"><p>Also - just how much fun we’ve had along the way! JFI dived into the trenches with us and still today do whatever it takes for us to succeed.</p></div>`,
                    results: `<p>The JFI team were able to influence a substantial amount of revenue that closed across Quarter 1 & 2.</p>`,
                    stats: `$35 million USD`,
                    author: {
                        name: 'Ryan Rayner',
                        role: 'Vice President of Marketing'
                    }
                }
            }
        ],
        ourPartners: [
            {
                key: 'Relm',
                name: 'Relm'
            },
            {
                key: 'Ackama',
                name: 'Ackama',
                website: 'https://www.ackama.com'
            },
            {
                key: 'MonsoonCreative',
                name: 'Monsoon Creative',
                website: 'https://monsooncreative.co.nz'
            }
        ],
        navigationIsOpened: false,
        expertiseNavigationIsOpened: false,
        bgClass: '',
        anchorToReach: null
    },
    mutations: {
        SET_BURGER_MENU(state, show = false) {
            state.navigationIsOpened = show;
        },
        SET_EXPERTISE_BURGER_MENU(state, show = false) {
            state.expertiseNavigationIsOpened = show;
        },
        SET_BG_CLASS(state, className = '') {
            state.bgClass = className;
        },
        SET_ANCHOR_TO_REACH(state, anchor = null) {
            state.anchorToReach = anchor;
        }
    },
    actions: {
        burgerMenuToggle(context) {
            context.commit('SET_BURGER_MENU', !context.state.navigationIsOpened);
        },
        expertiseBurgerMenuToggle(context) {
            context.commit('SET_EXPERTISE_BURGER_MENU', !context.state.expertiseNavigationIsOpened);
        },
        burgerMenuClose(context) {
            context.commit('SET_BURGER_MENU', false);
        },
        expertiseBurgerMenuClose(context) {
            context.commit('SET_EXPERTISE_BURGER_MENU', false);
        },
        setBgClass(context, className = '') {
            if (context.state.bgClass === className) {
                className = '';
            }
            context.commit('SET_BG_CLASS', className);
        },
        setAnchorToReach(context, anchor = null) {
            context.commit('SET_ANCHOR_TO_REACH', anchor);
        }
    }
});
