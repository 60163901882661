import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import simplebar from 'simplebar';
import Loading from 'vue-loading-overlay';
import KsVueScrollmagic from 'ks-vue-scrollmagic';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import VueMeta from 'vue-meta';
import './registerServiceWorker';

Vue.config.productionTip = process.env.NODE_ENV === 'production';
Vue.use(Loading, {
    color: '#ec3924',
    loader: 'spinner',
    width: 64,
    height: 64,
    backgroundColor: '#ffffff',
    opacity: 1,
    zIndex: 999
});
Vue.use(KsVueScrollmagic);
Vue.use(VuePlyr);
Vue.use(VueMeta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true
});
new Vue({
    router,
    store,
    simplebar,
    Loading,
    KsVueScrollmagic,
    VuePlyr,
    render: h => h(App)
}).$mount('#app');
